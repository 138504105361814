@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../../../Listing/Product/product-with-translucent-deal.scss";

.product-with-translucent-deal {
	border: none;

	&__deal {
		height: 100px;
	}
}
