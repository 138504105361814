@import "../../.styles/mixins";

$product-name-font-style: normal !default;
$product-name-text-transform: uppercase !default;
$common-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1) !default;
$product-cta-button-radius: none !default;
$product-cta-color: white !default;
$deal-separator-margin: 20px !default;
$product-deal-text-color: white !default;
$deal-background-color: $color-secondary !default;

.product-with-translucent-deal {
	border: none;

	&__main {
		width: 100%; // pour fixer debordement texte sur ie11
	}

	&__topbar {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		pointer-events: none; // sinon ca bloque le click sur la fiche produit
	}

	&__deal {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: $deal-background-color;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 60px;
		pointer-events: none;
		color: $product-deal-text-color;

		.product-deal {
			background: none;
			width: 100%;
			height: 60px;

			.product-deal {
				&__info {
					color: $product-deal-text-color;
					border-left: 1px solid $product-deal-text-color;
				}

				&__price-type {
					color: $product-deal-text-color;

					.amount {
						color: $product-deal-text-color;

						&__prefix {
							color: $product-deal-text-color;
						}
					}
				}
			}

			&__exclusive {
				color: $product-deal-text-color;

				@include gridle_state(md) {
					font-size: 2.8rem;
				}
			}
		}
	}

	.product-destination-title {
		font-size: 2rem;

		@include gridle_state(md) {
			letter-spacing: 0;
		}
	}

	&__picture {
		position: relative;
		height: 220px;
		cursor: pointer;

		.progressive-img {
			display: none;

			img {
				border-radius: $border-radius-big $border-radius-big 0 0;
			}
		}

		.background-progressive-image {
			display: block;
			border-radius: $border-radius-big $border-radius-big 0 0;

			&__image {
				border-radius: $border-radius-big $border-radius-big 0 0;
			}
		}

		// Pourvoir avoir le click droit ouvrir nouvel onglet depuis la photo du produit
		.relative-link {
			display: block;
			padding: 0;
			height: 100%;
			width: 100%;
			background: none;

			&__text {
				display: block;
				height: 100%;
				width: 100%;
			}
		}

		@include gridle_state(mc) {
			height: 240px;
		}

		@include gridle_state(sm) {
			height: 350px;
		}

		@include gridle_state(md) {
			height: 290px;

			.progressive-img {
				display: block;
			}

			.background-progressive-image {
				display: none;
			}
		}
	}

	&__badges {
		margin-top: 5px;
	}

	.product-name-with-category {
		flex-wrap: nowrap; // ellipsis

		&__name {
			text-align: left;
			text-transform: $product-name-text-transform;
			font-style: $product-name-font-style;
			margin-right: 10px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			color: $grey-dark;

			@include gridle_state(md) {
				font-size: 1.54rem;
			}
		}

		&__category {
			.category {
				.icon {
					height: 10px !important;
					width: 10px !important;
					margin-right: 2px;

					@include gridle_state(sm) {
						height: 15px;
						width: 15px;
						margin-right: 5px;
					}
				}
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: center;
		padding: 15px;
		border: none;
		background: white;
		box-sizing: border-box;
		border-radius: 0 0 $border-radius-big $border-radius-big;
		box-shadow: $common-box-shadow;

		@include gridle_state(md) {
			padding: 15px 20px;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		&--left {
			@include gridle_grid(19);
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;

			@include gridle_state(md) {
				@include gridle_grid(20);
			}
		}

		&--right {
			@include gridle_grid(5);
			@include gridle_row_align(right);
			align-items: center;
			justify-content: flex-end;

			@include gridle_state(md) {
				@include gridle_grid(4);
			}
		}
	}

	&__action {
		.relative-link {
			font-size: 1rem;
			display: inline-flex;
			width: 45px;
			min-height: 45px;
			border-radius: $product-cta-button-radius;

			&__text {
				color: $product-cta-color;
			}

			@include gridle_state(mc) {
				font-size: 1.2rem;
			}
		}
	}
}
