@import "~gridle/sass/gridle/gridle";

$padding-small: 14px;
$padding-large: 48px;

$settings: (
	context: 24,
	// number of columns in your grid
		column-width: null,
	// by default it's percentage based depending on the context but you can specify a column width yourself
		gutter-width: 4px,
	// size of the gutters
		gutter-height: 0,
	// size of top and bottom gutters
		gutter-top: 0,
	// size of top gutter
		gutter-bottom: 0,
	// size of bottom gutter
		gutter-left: 2px,
	// size of left gutter
		gutter-right: 2px,
	// size of right gutter
		direction: ltr,
	// direction
		dir-attribute: false,
	// if need to generate support for dir="rtl" in order to set part of layout direction
		states-classes: false,
	// if we need to generate the states classes
		name-multiplicator: 1,
	// if 5, generate gr-5, gr-10, etc...
		classes-prefix: "" // the prefix used for all classes,,,,,,,,,,
);

@include gridle_setup($settings);

@include gridle_register_state(
	mc,
	(
		min-width: 425px,
	)
);

@include gridle_register_state(
	sm,
	(
		min-width: 768px,
	)
);

@include gridle_register_state(
	md,
	(
		min-width: 1024px,
	)
);

@include gridle_register_state(
	lg,
	(
		min-width: 1441px,
	)
);

@include gridle_init();

// Décommenter ces 2 lignes pour générer le contenu #gridle-settings nécessaire pour le fonctionnement de gridle.js
// Copier le #gridle-settings généré depuis le css et le coller dans index.scss
// @see https://github.com/olivierbossel/gridle/issues/42
//$gridle-generate-json-settings: true;
//@include gridle_generate_classes();

// depreciated : use grid-container because we want to remove gridle dependancies
@mixin container {
	@include gridle_container();
	margin-right: auto;
	margin-left: auto;
	max-width: 978px;

	@include gridle_state(md) {
		max-width: 1024px;
	}
}

@mixin grid-container {
	margin-right: auto;
	margin-left: auto;
	max-width: 978px;

	@include gridle_state(md) {
		max-width: 1024px;
	}
}
