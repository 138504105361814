@import "~themes/theme";

h1 {
	@extend %h1;
}

h2 {
	@extend %h2;
}

h3 {
	@extend %h3;
}

h4 {
	@extend %h4;
}

$input-focus-text-highligthed-color: #b3d7fe;
