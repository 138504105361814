@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-home {
	&__hero {
		position: relative;
		height: 220px;

		.background-progressive-image {
			height: 160px;
		}

		@include gridle_state(md) {
			height: calc(100vh - 70px);

			&-content {
				@include container;
				position: absolute;
				left: 0;
				right: 0;
				height: 100%;
				top: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&__marketing {
		color: white;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;
		flex: 1 1 0%;

		@include gridle_state(md) {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	&__marketing-container {
		position: absolute;
		height: 130px;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(51, 51, 51, 0),
			$smartdp-search-marketing-color 60%,
			$smartdp-search-marketing-color 100%
		);
		padding: 0 15px 20px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include gridle_state(md) {
			background: none;
			height: auto;
			bottom: initial;
			position: initial;
			margin-right: 60px;
			padding: 0;
		}
	}

	&__subtitle {
		margin-top: 10px;
		line-height: 20px;

		@include gridle_state(md) {
			margin-top: 30px;
			font-size: 2rem;
			line-height: 26px;
		}
	}

	&__form {
		padding: 0 15px 20px;
		background: $color-primary;

		@include gridle_state(md) {
			position: relative;
			background: none;
			padding: 0;
			width: 355px;

			.auth__main {
				padding: 30px;
			}
		}
	}

	&__blocks {
		&-item {
			padding: 30px 15px;

			&:nth-child(2n + 1) {
				background: $grey-light;
			}

			> * {
				@include container;
			}

			.pp-carousel-block {
				padding: 0;

				.product-deal,
				.product-with-translucent-deal {
					pointer-events: none;

					&__info--left {
						width: 100%;
					}

					&__info--right {
						display: none;
					}
				}

				&__cta {
					display: none;
				}
			}

			@include gridle_state(md) {
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
	}
}
